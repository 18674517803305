

const MONTHNAME = [
    'januar',
    'februar',
    'mars',
    'april',
    'mai',
    'juni',
    'juli',
    'august',
    'september',
    'oktober',
    'november',
    'desember'
]

function GetShortMonthName(month_idx: number): string {
    let name = MONTHNAME[month_idx];
    if (name.length > 4) {
        name = name.substring(0, 3) + '.';
    }
    return name;
}

// Parse the input, taking only the date into the consideration.
// "input" is expected to be in ISO 8601.
export function ParseDateOnly(input: string): Date {
    const timeSeparatorIndex = input.indexOf("T");
    if (timeSeparatorIndex > -1) {
        return new Date(input.substring(0, timeSeparatorIndex));
    }
    return new Date(input);
}

/*
  example date: 2024-02-08T13:53:00.123

  dd - Full date            => 08
  mm - Full month           => 02
  mmm - Short textual month => feb.
  mmmm - Long textual month => februar
  yyyy - Full year          => 2024

  HH - Full 24 hour         => 13
  MM - Full minute          => 53
  SS - Full second          => 00
*/
export function FormatDate(d: Date, format: string): string {
    let out = '';
    for (let i = 0; i < format.length; ++i) {
        switch (format[i]) {
            case 'H':
                if (format.length > i + 1 && format[i + 1] == 'H') {
                    out += d.getHours().toString().padStart(2, '0');
                    i++;
                    continue;
                }
                break;
            case 'M':
                if (format.length > i + 1 && format[i + 1] == 'M') {
                    out += d.getMinutes().toString().padStart(2, '0');
                    i++;
                    continue;
                }
                break;
            case 'S':
                if (format.length > i + 1 && format[i + 1] == 'S') {
                    out += d.getSeconds().toString().padStart(2, '0');
                    i++;
                    continue;
                }
                break;
            case 'd':
                if (format.length > i + 1 && format[i + 1] == 'd') {
                    out += d.getDate().toString().padStart(2, '0');
                    i++;
                    continue;
                }
                break;
            case 'm':
                let numConsecutive = 0;
                for (let j = i + 1; j < format.length; ++j) {
                    if (format[j] == 'm') {
                        numConsecutive++;
                    } else {
                        break;
                    }
                }

                switch (numConsecutive) {
                    case 1:
                        out += (d.getMonth() + 1).toString().padStart(2, '0');
                        i += numConsecutive;
                        continue;
                    case 2:
                        out += GetShortMonthName(d.getMonth());
                        i += numConsecutive;
                        continue;
                    case 3:
                        out += MONTHNAME[d.getMonth()];
                        i += numConsecutive;
                        continue;
                }
                break;
            case 'y':
                if (format.length > i + 3 &&
                    format[i + 1] == 'y' &&
                    format[i + 2] == 'y' &&
                    format[i + 3] == 'y') {
                    out += d.getFullYear().toString().padStart(4, '0');
                    i += 3;
                    continue;
                }
            default:
                break;
        }
        out += format[i];
    }

    return out;
}