export function ShowModal(modalId: string) {
    const modalDiv = document.getElementById(modalId);
    const closeButton = modalDiv.getElementsByClassName('close')[0] as HTMLSpanElement;
    closeButton.onclick = function () {
        modalDiv.style.display = 'none';
    }

    modalDiv.style.display = 'block';
    window.onclick = function (event: MouseEvent) {
        if (event.target == modalDiv) {
            modalDiv.style.display = "none";
        }
    }
}
