import { Language, LiveEvent, NewChanges, NewMyWebSocketType } from './common/types';
import { NewWebsocket } from './common/mywebsocket'

import { GetInitialLanguage, UpdateLanguage } from './common/i18n';
import { ShowModal } from './common/modal';
import { StartPingTimer } from './common/ping';
import { RandomString } from './common/clientid';
import { Column, ColumnTypeEnum, ErikTable, ForEachFilter, RenderTypeEnum, SortDirection } from './common/table';
import { FormatDate, ParseDateOnly } from './common/datetime';

let datatable: ErikTable<string, LiveEvent> = null;
let webSocket: NewMyWebSocketType = null;
let currentLanguage: Language = GetInitialLanguage();

window.onload = function () {
    ReCreateDataTable();

    StartListeners();

    (document.getElementById('language-en-us') as HTMLImageElement).onclick = function () {
        currentLanguage = UpdateLanguage('en-US', false);
        ReCreateDataTable();
    };

    (document.getElementById('language-nb-no') as HTMLImageElement).onclick = function () {
        currentLanguage = UpdateLanguage('nb-NO', false);
        ReCreateDataTable();
    };


    (document.getElementById('more-info') as HTMLDivElement).onclick = function () {
        ShowModal('more-info-modal');
    };

    currentLanguage = UpdateLanguage(currentLanguage.code, true);

    StartPingTimer('index.html', function () {
        return {
            'language': currentLanguage.code
        };
    });
}

// Caller must make sure that datatable.draw() is called.
function AddTodayMarking(): void {
    // Remove any existing marker that may exists.
    let toRemove: string[] = [];
    datatable.forEach(function (event) {
        if (event.is_today_marker) {
            toRemove.push(event.event_id);
        }

    }, ForEachFilter.AllRows);

    datatable.deleteRows(toRemove);

    // Find if we have any active event. If not, add a marker for today.
    let hasActiveEvent = false;
    datatable.forEach(function (row) {
        if (IsActiveEvent(new Date(), row) && !row.is_today_marker && row.public) {
            hasActiveEvent = true;
        }
    }, ForEachFilter.AllRows);

    if (!hasActiveEvent) {
        const todayMarker: LiveEvent = {
            description: currentLanguage.no_event_today,
            event_id: RandomString(20),
            first_date: FormatDate(new Date(), 'yyyy-mm-dd'),
            last_date: FormatDate(new Date(), 'yyyy-mm-dd'),
            is_today_marker: true,
            organizer: '',
            public: true,
            race_type: ''
        };

        datatable.upsertRow(todayMarker);
    }
}

function StartListeners() {
    webSocket = new NewWebsocket('events', ['events']);
    webSocket.DataReceived = function (collectionName, data, fullReload) {
        if (collectionName != 'events') {
            return;
        }

        const changes: NewChanges<LiveEvent> = data;
        if (fullReload) {
            datatable.clear();
        }

        if (changes.delete != null) {
            const rowIds: string[] = [];
            for (const row of changes.delete) {
                const id = '#' + row[0];
                rowIds.push(id);
            }
            datatable.deleteRows(rowIds);
        }

        if (changes.upsert != null) {
            datatable.upsertRows(changes.upsert);
        }

        AddTodayMarking();
        datatable.newDraw();
    }
}

function ReCreateDataTable() {
    var data: LiveEvent[] = [];
    if (datatable !== null) {
        data = datatable.data();
        datatable.clear();
    }

    document.getElementById('index-table').innerHTML = '';

    let columns: Column<LiveEvent>[] = [
        {
            'data': 'description',
            'title': currentLanguage.index_race_description,
            'render': UrlRender
        },
        {
            'data': 'race_type',
            'title': currentLanguage.index_race_type,
            'render': UrlRender
        },
        {
            'data': 'organizer',
            'title': currentLanguage.index_race_organizer,
            'render': UrlRender
        },
        {
            'data': 'first_date',
            'title': currentLanguage.index_race_date,
            'render': EventDateRender,
            'sortType': ColumnTypeEnum.Number
        },
    ];

    datatable = new ErikTable('index-table', {
        'columns': columns,
        'data': data,
        'id': 'event_id',
        'sort': [
            {
                sort_direction: SortDirection.Descending,
                column_idx: columns.length - 1
            }
        ],
        'filter': function (event) {
            return event.public;
        },
        'conditionalRowStyling': RowStyle
    });

    AddTodayMarking();
    datatable.newDraw();
}

function UrlRender(data: any, row: LiveEvent, type: RenderTypeEnum) {
    if (type == RenderTypeEnum.Sort) {
        return data;
    }

    if (row.is_today_marker) {
        return '<i>' + data + '</i>';
    }

    return '<a href="event.html?event_id=' + row.event_id + '">' + data + '</a>';
}

function EventDateRender(_: any, row: LiveEvent, type: RenderTypeEnum): any {

    if (type == RenderTypeEnum.Sort) {
        return Date.parse(row.first_date);
    }

    if (row.is_today_marker) {
        return '';
    }

    const firstDate = new Date(row.first_date);
    const lastDate = new Date(row.last_date);

    let output = '';
    if (firstDate.valueOf() == lastDate.valueOf()) {
        output = FormatDate(firstDate, 'dd.mm.yyyy');
    } else if (firstDate.getFullYear() != lastDate.getFullYear()) {
        output = FormatDate(firstDate, 'dd.mm.yyyy') + '-' + FormatDate(lastDate, 'dd.mm.yyyy');
    } else if (firstDate.getMonth() != lastDate.getMonth()) {
        output = FormatDate(firstDate, 'dd.mm') + '-' + FormatDate(lastDate, 'dd.mm.yyyy');
    } else {
        output = FormatDate(firstDate, 'dd.') + '-' + FormatDate(lastDate, 'dd.mm.yyyy');
    }

    return UrlRender(output, row, type);
}

function IsSameOrAfter(a: Date, b: Date): boolean {
    return a.valueOf() >= b.valueOf()
}

function IsSameOrBefore(a: Date, b: Date): boolean {
    return a.valueOf() <= b.valueOf();
}

function RowStyle(data: LiveEvent): string[] {
    var out = [];
    if (IsActiveEvent(new Date(), data)) {
        out.push('active-event');
    }

    return out;
}

export function IsActiveEvent(now: Date, event: LiveEvent): boolean {
    const today = new Date(FormatDate(now, 'yyyy-mm-dd'));
    const firstDate = ParseDateOnly(event.first_date);
    const lastDate = ParseDateOnly(event.last_date);

    return IsSameOrAfter(today, firstDate) && IsSameOrBefore(today, lastDate);
}
